import React from 'react'
import Logo from './Logo';
import MyVerticallyCenteredModal from '../Helpers/Modals/MyVerticallyCenteredModal';
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import './Header.css';
// import Button from 'react-bootstrap/Button'
// import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/Form'
import { Button, Dropdown, Menu, Label, Icon } from 'semantic-ui-react'
import SvgComponent from '../Helpers/SvgComponent';
import Gear from '../../../assets/images/Gear.svg';
import Building from '../../../assets/images/Building.svg';

class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visibleGeocercas: false,
            visibleUsuariosTemporales: false,
            visibleBitacoraMantenimiento: false
        }
    }

    renderSandwich() {
        var render = <span>---</span>;
        return render;
    };

    renderLogo() {
        var origin = window.location.origin;
        var render = (
            <div className="Logo">
                <a href={origin} target="_parent">
                    <Logo />
                </a>
            </div>
        );
        return render;
    };

    renderUserInfo() {
        var renderMenus = null;
        if (this.props.usuarioActual.Tipo !== 4) {
            renderMenus = (
                <React.Fragment>
                    <Dropdown.Item className='text-right' onClick={e => this.AbrirMenuGeocercas()}>Geocercas</Dropdown.Item>
                    <Dropdown.Item className='text-right' onClick={e => this.AbrirMenuUsuariosTemporales()}>Usuarios temporales</Dropdown.Item>
                    {this.props.usuarioActual.VisibleVentanaBitacoraMantenimiento 
                    ? (<Dropdown.Item className='text-right' onClick={e => this.AbrirMenuBitacoraMantenimiento()}>Bitácora de mantenimientos</Dropdown.Item>) 
                    : null}
                    <Dropdown.Divider />
                </React.Fragment>
            );
        }

        var iconRender = (
            <React.Fragment>
                <i className='bg-gris-claro rounded-sm IconoIzquierda'>
                    <SvgComponent icono={Building} />
                </i>
                <i className="bg-naranja text-white rounded-right IconoDerecha">
                    <SvgComponent icono={Gear} />
                </i>
            </React.Fragment>
        );

        var render = (
            <div className="UserInfo">
                <Dropdown
                    text={this.props.usuarioActual.Nombre}
                    icon={iconRender}
                    floating
                    labeled
                    button
                    className='icon bg-white text-gris-oscuro DropDownUserSettings'
                >
                    <Dropdown.Menu>
                        {renderMenus}
                        <Dropdown.Item href="/Logout.aspx" className='text-right'>Salir</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
        return render;
    };

    AbrirMenuGeocercas() {
        this.setState({ visibleGeocercas: true });
    }

    CerrarMenuGeocercas() {
        this.setState({ visibleGeocercas: false });
    }

    AbrirMenuUsuariosTemporales() {
        this.setState({ visibleUsuariosTemporales: true });
    }

    CerrarMenuUsuariosTemporales() {
        this.setState({ visibleUsuariosTemporales: false });
    }

    AbrirMenuBitacoraMantenimiento() {
        this.setState({ visibleBitacoraMantenimiento: true });
    }

    CerrarMenuBitacoraMantenimiento() {
        this.setState({ visibleBitacoraMantenimiento: false });
    }

    renderMenus() {
        const urlGeocercas = process.env.REACT_APP_SERVICES_GEOCERCAS + "IdCuenta=" + this.props.usuarioActual.IdCuenta;
        const urlUsuariosTemporales = process.env.REACT_APP_SERVICES_USUARIOSTEMPORALES + "IdCuenta=" + this.props.usuarioActual.IdCuenta;
        const urlBitacoraMantenimiento = process.env.REACT_APP_SERVICES_BITACORAMANTENIMIENTO + "IdCuenta=" + this.props.usuarioActual.IdCuenta;
        var render = null;

        var render = (
            <React.Fragment>
                <MenuAdicional title="Geocercas" iframeSrc={urlGeocercas} visible={this.state.visibleGeocercas} onHide={() => this.CerrarMenuGeocercas()} />
                <MenuAdicional title="Usuarios temporales" iframeSrc={urlUsuariosTemporales} visible={this.state.visibleUsuariosTemporales} onHide={() => this.CerrarMenuUsuariosTemporales()} />
                <MenuAdicional title="Bitácora de mantenimientos" iframeSrc={urlBitacoraMantenimiento} visible={this.state.visibleBitacoraMantenimiento} onHide={() => this.CerrarMenuBitacoraMantenimiento()} />
            </React.Fragment>
        );

        return render;
    }

    renderSettings() {
        return null;
    }

    render() {
        let finalRender = (
            <div id="app-header" className="bg-azul">
                {this.renderLogo()}
                {this.renderUserInfo()}
                {this.renderSettings()}
                {this.renderMenus()}
            </div>
        );

        return finalRender;
    }
}

const MenuAdicional = (props) => {
    let finalRender = (
        <MyVerticallyCenteredModal
            title={props.title}
            url={props.iframeSrc}
            visible={props.visible}
            onHide={props.onHide} />
    );

    return finalRender;
}

export default Header;